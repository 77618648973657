import * as React from 'react';
import PropTypes from 'prop-types';
import useIsMountedRef from 'use-is-mounted-ref';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Fullscreen, Minimize } from '@mui/icons-material';
import {
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Stack,
    Tabs, Tab,
    Container,
    Button,
    Card,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box } from '@mui/system';
import Page from '../../../components/Page';
import { a11yProps, postData } from '../../../utils/generalFunctions';
import { FUNDRAISING, GROUP_INFO } from '../../../utils/urls';
import useResponsive from '../../../hooks/useResponsive';
import TabPanel from '../management/TabPanel';
import TransactionsTable from '../management/@tables/Transactions';
import DashboardInfoCards from '../fundraising/components/DashboardInfoCards';
import FundraisingTransactions from '../management/@tables/FundraisingTransactions';
import Withdraw from '../../../pages/Withdraw';
import Pledges from '../management/@tables/Pledges';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
FundraisingManage.propTypes = {
    children: PropTypes.node,
    item: PropTypes.object.isRequired,
};

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
export default function FundraisingManage({ open, setOpen, item }) {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch()
    const isDesktop = useResponsive('up', 'md');
    const [fullScreen, setFullscreen] = React.useState(true);
    const fundraiser = useSelector(state => state.fundraiser)

    const handleClose = () => {
        setValue(0)
        setOpen(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleFullScreen = () => {
        setFullscreen(!fullScreen);
    };
    const isMountedRef = useIsMountedRef();

    const getItem = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                document.body.style.zoom = "75%";
                await postData(`${FUNDRAISING}/${item.id}`, null, 'GET')
                    .then(response => {
                        if (response.status) {
                            const { item } = response
                            dispatch({ type: 'set', fundraiser: item })
                        }
                    });
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);


    React.useEffect(() => {
        getItem();
    }, [getItem, open]);

    const renderContent = (
        <Container maxWidth="lx">
            <TabPanel  value={value} index={0}>
                <Page title={`${(item.description && item.description.split(' ')[0])||'M-Chango'}| Dashboard`}>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h6">{(item.description && item.description.split(' ')[0])||'M-Chango'},Dashboard</Typography>
                    </Box>
                    {fundraiser && <DashboardInfoCards fundraiser={fundraiser} />}
                </Page>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Page title={`${(item.description && item.description.split(' ')[0])||'M-Chango'}| Contributions`}>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h6">{(item.description && item.description.split(' ')[0])||'M-Chango'},Transfer Payments</Typography>
                    </Box>
                    <Withdraw typeValue='fundraiser' savings_id={item.savings_id} referenceId={item.id} callbackFunc={() => { }} />
                </Page>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Page title={`${(item.description && item.description.split(' ')[0])||'M-Chango'}| Contributions`}>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h6">{(item.description && item.description.split(' ')[0])||'M-Chango'},Contributions</Typography>
                    </Box>
                    <FundraisingTransactions />
                </Page>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <Page title={`${(item.description && item.description.split(' ')[0])||'M-Chango'}| Contributions`}>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h6">{(item.description && item.description.split(' ')[0])||'M-Chango'},View & Create Pledges</Typography>
                    </Box>
                    <Pledges item={item}/>
                </Page>
            </TabPanel>

            <TabPanel value={value} index={4}>
                <Page title={`${(item.description && item.description.split(' ')[0])||'M-Chango'}| Transactions History`}>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h6">{(item.description && item.description.split(' ')[0])||'M-Chango'},Transactions History</Typography>
                    </Box>
                    <Card> <TransactionsTable savings_id={item.savings_id} /></Card>
                </Page>
            </TabPanel>
        </Container>
    )

    return (
        <Dialog
            PaperComponent={PaperComponent}
            transitionDuration={500}
            fullScreen={fullScreen}
            maxWidth='xl'
            fullWidth
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar color='default' sx={{ display: isDesktop ? 'none' : 'block', position: 'sticky' }}>
                <Toolbar>

                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {(item.description && item.description.split(' ')[0])||'M-Chango'}
                    </Typography>
                    <Stack direction='row' spacing={-1}>
                        <IconButton
                            focusRipple
                            edge="start"
                            color="inherit"
                            onClick={toggleFullScreen}
                            aria-label="close"
                        >
                            {fullScreen ? <Minimize sx={{ mb: 2 }} /> : <Fullscreen />}
                        </IconButton>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: `${fullScreen ? 244 : 500}` }}>
                <Stack
                    alignItems="center"
                    spacing={3}
                    sx={{ p: 2, borderRadius: 2, position: 'relative' }}
                >
                    <Tabs selectionFollowsFocus
                        orientation={isDesktop ? "vertical" : 'horizontal'}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Group Dashboard for managers"
                        sx={{ borderRight: 1, borderColor: 'divider', width: !isDesktop ? '100%' : 244 }}
                    >
                        <Tab sx={{ alignSelf: 'start' }} label="Dashboard" {...a11yProps(0)} />
                        <Tab sx={{ alignSelf: 'start' }} label="Transfer Money" {...a11yProps(1)} />
                        <Tab sx={{ alignSelf: 'start' }} label="Contributions" {...a11yProps(2)} />
                        <Tab sx={{ alignSelf: 'start' }} label="Manage Pledges" {...a11yProps(3)} />
                        <Tab sx={{ alignSelf: 'start' }} label="Transactions History" {...a11yProps(4)} />
                    </Tabs>

                    <Button
                        sx={{ display: !isDesktop ? 'none' : 'block' }}
                        fullWidth
                        onClick={() => handleClose()}
                        variant="outlined"
                    >
                        Close
                    </Button>

                    {!isDesktop && renderContent}
                </Stack>

                {isDesktop && renderContent}
            </Box>

        </Dialog>
    );
}
