/* eslint-disable array-callback-return */
/* eslint-disable no-extend-native */

import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useIsMountedRef from 'use-is-mounted-ref';
// material
import {
    Stack,
    Box,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Alert, DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    MenuItem,
    Select,
    OutlinedInput,
    ListItemText,
    InputLabel,
    Chip,
    Snackbar
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LoadingButton } from '@mui/lab'; 
import { BANKS, CREATE_APPROVAL_REQUEST, MEMBERS_BY_GROUPID } from '../../../../utils/urls';
import Iconify from '../../../../components/Iconify';
import { postData } from '../../../../utils/generalFunctions';
import ConfirmDialog from '../../../../components/ConfirmDialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



const schema = Yup.object().shape({
    amount: Yup.number()
        .min(10, 'Minimum transfer amount is 10')
        .max(999999, 'Maximum amount is 999999')
        .required('Payment amount is required'),
    receive_on_mobile: Yup.string()
        .required('Telephone number is required.'),
    recive_on_date: Yup.string().default(new Date())
        .required('Select a valid date time'),
    channel: Yup.number().required('Please select a payment option'),
    subscriptionId: Yup.number().required('Please select a member'),
    bankCode: Yup.string().when("channel", {
        is: '2',
        then: Yup.number('Select a destination bank')
            .required('Select a destination bank')
    }).notRequired(),
    account_number: Yup.string().min(4, 'Provide a valid account number')
        .when("channel", {
            is: '2' || '3',
            then: Yup.number('Provide a valid account number')
                .required('Provide a valid account number')
        }).notRequired(),
    reference: Yup.string()
        .required('Please provide a payment reference.'),
    member_name: Yup.string()
        .required('Please provide a valid member name.'),
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

SchedulePayment.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    group: PropTypes.object,
    setValueSelect: PropTypes.func.isRequired,
};


export default function SchedulePayment(props) {
    const { open, setOpen, setValueSelect, group } = props
    const [message, setMessage] = React.useState('')
    const [banks, setBanks] = React.useState([])
    const [methods, setMethods] = React.useState([])
    const [members, setMembers] = React.useState([])

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error'
    });
    const [value, setValue] = React.useState(new Date());

    const handleChange = (newValue) => {
        formik.setFieldValue('recive_on_date', newValue)
        setValue(newValue);
    };


    const isMountedRef = useIsMountedRef();

    const loadData = React.useCallback(async () => {
        if (isMountedRef.current) {
            await postData(BANKS, {}, 'GET').then((response) => {
                try {
                    setBanks(response.bank_list)
                    setMethods(response.withdrawal_options)
                } catch (error) {
                    console.log(error);
                }
            })

            await postData(`${MEMBERS_BY_GROUPID}${group.id}`, {}, 'GET').then((response) => {
                try {
                    setMembers(response.data.members)
                } catch (error) {
                    console.log(error);
                }
            })
        }

    }, [isMountedRef])


    React.useEffect(() => {
        loadData()
    }, [loadData])

    const formik = useFormik({
        initialValues: {
            groupId: group.id,
            savingsId: group.savingsId,
            amount: '',
            subscriptionId: '',
            memberId: '',
            receiving_account_id: false,
            receive_on_mobile: '',
            recive_on_date: '',
            channel: 1,
            bankCode: '',
            account_number: '',
            reference: '',
            member_name: ''
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                const user = members.filter(item => item.id === values.subscriptionId)[0]
                const data = {
                    "groupId": group.id,
                    "savingsId": group.savingsId,
                    "amount": values.amount,
                    "group_account_number": user.contribution_account,
                    "memberId": user.memberId,
                    "receiving_account_id": values.subscriptionId,
                    "receive_on_mobile": values.receive_on_mobile,
                    "recive_on_date": values.recive_on_date,
                    "channel": values.channel,
                    "bankCode": values.bankCode,
                    "account_number": values.account_number,
                    "reference": values.reference,
                    "member_name": values.member_name
                }

                postData(CREATE_APPROVAL_REQUEST, data)
                    .then(data => {
                        if (data.status) {
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'success' });
                            formik.resetForm()
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setValue('');
                            setMessage('')
                            setOpen()
                            setValueSelect(1)
                        } else {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'error' });
                        }
                    });
            } catch (error) {
                console.log(error);
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, horizontal, vertical, isOpen } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'error' });
    };

    const handleClose = () => {
        setOpen();
    };

    const ITEM_HEIGHT = 60;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    const renderBankFields = (
        <>
            <FormControl >
                <InputLabel id="bank-destination-label">Destination Bank</InputLabel>
                <Select

                    labelId="bank-destination-label"
                    id="bank-destination"
                    {...getFieldProps('bankCode')}
                    input={<OutlinedInput label="Destination Bank" />}
                    renderValue={(value) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            <Chip key={value} label={banks.filter(p => p.code === value)[0].name} />
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value="">
                        <em>Choose an option</em>
                    </MenuItem>
                    {banks && banks.map(({ code, name }) => (
                        <MenuItem key={code} value={code}>
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                autoComplete="account_number"
                type="text"
                label={`${values.bankCode && banks.filter(p => p.code === values.bankCode)[0].name} A/C`}
                {...getFieldProps('account_number')}
                error={Boolean(touched.account_number && errors.account_number)}
                helperText={touched.account_number && errors.account_number}
            />


        </>
    )

    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const handleFormSubmit = () => {
        if (formik.isValid) {
            setConfirmMessage('You are about to schedule money transfer. On successful submission your group signatories will be required to visit the approval tab to authorize this payment. Click proceed to submit')
            setOpenConfirm(true)
        } else {
            formik.validateForm()
            setMessage('Please check that are fields are filled correctly.')
            setState({ ...state, isOpen: true, severity: 'error' });
        }
    }

    const handleOnDecline = () => {
        setOpenConfirm(false)
    }

    const handleOnConfirm = () => {
        setOpenConfirm(false)
        formik.submitForm()
        setConfirmMessage('')
    }

    return (
        <div>
            <ConfirmDialog isSubmitting={formik.isSubmitting} onDismiss={handleOnDecline} title='Request transfer approval?' dismissText='Close!' open={openConfirm} message={confirmMessage} onConfirm={handleOnConfirm} onDecline={handleOnDecline} />
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={10000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth='md'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Schedule Payment
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Alert style={{ display: message === "" ? 'none' : undefined }} severity={severity} sx={{ marginBottom: 2 }}>{message}</Alert>
                    <FormikProvider value={formik}>
                        <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <FormControl>
                                    <FormLabel id="payment_channel">Payment Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="payment_channel"
                                        name="row-radio-buttons-channel"
                                        {...getFieldProps('channel')}
                                    >
                                        {
                                            methods && methods.map(({ id, position, name }) => <FormControlLabel key={position} value={id} control={<Radio />} label={name} />)
                                        }

                                    </RadioGroup>
                                </FormControl>
                                <FormControl >
                                    <InputLabel id="group-member-label">Member</InputLabel>
                                    <Select
                                        onChange={(e) => {
                                            const member = members.filter(p => p.id === e.target.value)[0]

                                            formik.setFieldValue('member_name', member.member_name)
                                            formik.setFieldValue('receive_on_mobile', member.mobile)
                                            formik.setFieldValue('subscriptionId', e.target.value)
                                        }}

                                        // {...getFieldProps('subscriptionId')}
                                        labelId="group-member-label"
                                        id="group-member-label"
                                        input={<OutlinedInput label="Member" />}
                                        renderValue={(value) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                <Chip key={value} label={members.filter(p => p.id === value)[0].display} />
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select the receivers profile</em>
                                        </MenuItem>
                                        {members && members.filter(item => !item.is_deleted).map(({ id, display }) => (
                                            <MenuItem key={id} value={id}>
                                                <ListItemText primary={display} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    autoComplete="amount"
                                    type="text"
                                    label="Transaction amount"
                                    {...getFieldProps('amount')}
                                    error={Boolean(touched.amount && errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Chip label='KES' />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Stack spacing={3} style={{ display: values.channel !== '2' ? 'none' : undefined }}>
                                    {renderBankFields}
                                </Stack>


                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        label='Disbursement date'
                                        value={value}
                                        minDateTime={new Date()}
                                        ampm
                                        ampmInClock
                                        onChange={handleChange}
                                        isInvalid={touched.recive_on_date && !!errors.recive_on_date}
                                        isValid={touched.recive_on_date && !errors.recive_on_date}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    style={{ display: values.channel === '3' ? 'none' : undefined }}
                                    fullWidth
                                    autoComplete="receive_on_mobile"
                                    type="text"
                                    label='Telephone number'
                                    {...getFieldProps('receive_on_mobile')}
                                    error={Boolean(touched.receive_on_mobile && errors.receive_on_mobile)}
                                    helperText={touched.receive_on_mobile && errors.receive_on_mobile}
                                />

                                <TextField
                                    style={{ display: values.channel !== '3' ? 'none' : undefined }}
                                    fullWidth
                                    autoComplete="account_number"
                                    type="text"
                                    label='Wallet A/C'
                                    {...getFieldProps('account_number')}
                                    error={Boolean(touched.account_number && errors.account_number)}
                                    helperText={touched.account_number && errors.account_number}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="member_name"
                                    type="text"
                                    label='Member name'
                                    {...getFieldProps('member_name')}
                                    error={Boolean(touched.member_name && errors.member_name)}
                                    helperText={touched.member_name && errors.member_name}
                                />


                                <TextField
                                    multiline
                                    fullWidth
                                    autoComplete="reference"
                                    type="text"
                                    label='Payment reference (private)'
                                    {...getFieldProps('reference')}
                                    error={Boolean(touched.reference && errors.reference)}
                                    helperText={touched.reference && errors.reference}
                                />
                            </Stack>


                            <DialogActions>
                                <LoadingButton
                                    autoFocus
                                    size="medium"
                                    variant="contained"
                                    loading={isSubmitting}
                                    onClick={() => formik.resetForm()}
                                >
                                    Clear
                                </LoadingButton>

                                <LoadingButton
                                    autoFocus
                                    size="medium"
                                    // type="submit"
                                    onClick={handleFormSubmit}
                                    color='secondary'
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Schedule
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    </FormikProvider>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}
