import * as React from 'react';
import PropTypes from 'prop-types';
import useIsMountedRef from 'use-is-mounted-ref';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Fullscreen, Minimize } from '@mui/icons-material';
import {
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Stack,
    Tabs, Alert,
    Snackbar,
    Tab,
    Container,
    Button,
    Card,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box } from '@mui/system';
import Page from '../../../components/Page';
import { Contributions, GroupCard, Payments } from '../management';
import { a11yProps, postData } from '../../../utils/generalFunctions';
import { GROUP_INFO, PROFITS } from '../../../utils/urls';
import useResponsive from '../../../hooks/useResponsive';
import StakeHolders from '../management/@members';
import GroupManagers from '../management/@admins';
import Iconify from '../../../components/Iconify';
import { fCurrency } from '../../../utils/formatNumber';
import TabPanel from '../management/TabPanel';
import TableBanking from '../management/@TableBanking';
import TransactionsTable from '../management/@tables/Transactions';
import ContributionDays from './ContributionDays';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
GroupManage.propTypes = {
    children: PropTypes.node,
    group: PropTypes.object.isRequired,
};

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
export default function GroupManage({ open, setOpen, group }) {
    const [state, setState] = React.useState({
        current_balance: 0,
        tuliza_payments: 0,
        deletedMembers: 23,
        total_penalties_paid_in: 0,
        total_service_charges_paid: 0,
        members: 0,
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error'
    })
    const data = useSelector(data => data.selectedGroup)
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch()
    const isDesktop = useResponsive('up', 'md');
    const [fullScreen, setFullscreen] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [content, setContent] = React.useState("")
    const [openProfitMove, setOpenProfitMove] = React.useState(false)
    const { severity, horizontal, vertical, isOpen } = state;



    const handleClose = () => {
        setValue(0)
        setOpen(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleFullScreen = () => {
        setFullscreen(!fullScreen);
    };
    const isMountedRef = useIsMountedRef();

    const getGroupStatus = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                document.body.style.zoom = "75%";
                await postData(`${GROUP_INFO}/${group.id}`, null, 'GET')
                    .then(response => {
                        if (response.status) {
                            const { data } = response
                            setState(data)
                            dispatch({ type: 'set', selectedGroup: data })
                        }
                    });
            }



        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        getGroupStatus();
    }, [getGroupStatus, open]);


    const Components = [
        {
            title: 'Dashboard',
            component: <GroupCard current_balance={(state.current_balance)} members={state.members} service_charge_balance={state.total_service_charges_paid} penalties_balance={state.total_penalties_paid_in} tuliza_payments={state.tuliza_payments} deleted_accounts={state.deletedMembers} />
        },
        {
            title: 'Payments',
            component: <Payments group={group} getPayments={getGroupStatus} payments={state.schedules && state.schedules} />
        },
        {
            title: 'Contributions',
            component: <Contributions group={group} />
        },
        {
            title: 'Members',
            component: <StakeHolders getGroupStatus={getGroupStatus} group={group} />
        },
        {
            title: 'Managers',
            component: <GroupManagers />
        },
        {
            title: 'Loan Management',
            component: <TableBanking />
        },
        {
            title: 'Transactions',
            component: <Card> <TransactionsTable savings_id={data && data.savings_id} /></Card>
        },
        {
            title: 'Contribution Days',
            component: <ContributionDays group={group}/>
        }
    ]

    const renderContent = (
        <Container maxWidth="xl">
            {
                Components.map(({ component, title }, index) => (
                    <TabPanel value={value} key={index} index={index}>
                        <Page title={`${group.name}| ${title}`}>
                            <Box sx={{ pb: 5 }}>
                                <Typography variant="h6">{group.name},{title}</Typography>
                            </Box>
                            {component}
                        </Page>
                    </TabPanel>
                ))
            }

        </Container>
    )



    const transferIncome = () => {
        setContent(`To process this request, please enter your comments here to ${`confirm the transfer of KES ${fCurrency(state.total_penalties_paid_in + state.total_service_charges_paid)} from account ${group.name} to your wallet`}.`)
        setOpenProfitMove(true)
    }
    const onDismissMoveProfit = () => {
        setIsSubmitting(false)
        setOpenProfitMove(false)
    }
    const [message, setMessage] = React.useState("")
    const onConfirmMoveProfit = () => {
        const payload = {
            groupId: group.id,
            reason
        }

        postData(PROFITS, payload).then((resp) => {
            setIsSubmitting(false)
            if (resp.status) {
                setOpenProfitMove(false)
                getGroupStatus()
                setMessage(`${resp.message}`)
                setReason("")
                setState({ ...state, isOpen: true, severity: 'success' });
            } else {
                setMessage(`${resp.message}`)
                setState({ ...state, isOpen: true, severity: 'error' });
            }
        }).catch((er) => {
            console.log(er);
            setIsSubmitting(true)
        })

    }
    const handleSnackClose = () => {
        setMessage('')
        setState({ ...state, isOpen: false });
    };
    return (
        <div>

            <Dialog
                PaperComponent={PaperComponent}
                // transitionDuration={500}
                fullScreen={fullScreen}
                maxWidth='xl'
                fullWidth
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >


                <AppBar color='default' sx={{ display: isDesktop ? 'none' : 'block', position: 'sticky' }}>
                    <Toolbar>

                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {group.name}
                        </Typography>
                        <Stack direction='row' spacing={-1}>
                            <IconButton
                                focusRipple
                                edge="start"
                                color="inherit"
                                onClick={toggleFullScreen}
                                aria-label="close"
                            >
                                {fullScreen ? <Minimize sx={{ mb: 2 }} /> : <Fullscreen />}
                            </IconButton>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: `${fullScreen ? 244 : 500}` }}>
                    <Stack
                        alignItems="center"
                        spacing={3}
                        sx={{ p: 2, borderRadius: 2, position: 'relative' }}
                    >
                        <Tabs selectionFollowsFocus
                            orientation={isDesktop ? "vertical" : 'horizontal'}
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Group Dashboard for managers"
                            sx={{ borderRight: 1, borderColor: 'divider', width: !isDesktop ? '100%' : 244 }}
                        >
                            <Tab sx={{ alignSelf: 'start' }} label="Dashboard" {...a11yProps(0)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Manage Payments" {...a11yProps(1)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Manage Contributions" {...a11yProps(2)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Manage Members" {...a11yProps(3)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Manage Admins" {...a11yProps(4)} />
                            <Tab sx={{ alignSelf: 'start', display: group.account_type !== 4 ? 'none' : undefined }} label="Loans & Approvals" {...a11yProps(5)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Transactions" {...a11yProps(6)} />
                            <Tab sx={{ alignSelf: 'start' }} label="Contribution Days" {...a11yProps(7)} />
                        </Tabs>
                        {data && data.is_owner ? <Button sx={{ display: value !== 0 ? 'none' : 'block' }}
                            color='info'
                            onClick={transferIncome}
                            variant="text"
                        >
                            <Iconify icon="fa6-solid:hand-holding-heart" width={15} height={15} />Transfer Income<Iconify icon="akar-icons:arrow-right" pt={1} width={20} height={20} />Wallet
                        </Button> : ''}

                        <Button
                            sx={{ display: !isDesktop ? 'none' : 'block' }}
                            fullWidth
                            onClick={() => handleClose()}
                            variant="outlined"
                        >
                            Close
                        </Button>

                        {!isDesktop && renderContent}
                    </Stack>


                    {isDesktop && renderContent}


                    <Dialog open={openProfitMove} onClose={onDismissMoveProfit}>
                        <DialogTitle>Provide your comment</DialogTitle>
                        <DialogContent>
                            <Snackbar key={vertical + horizontal} anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }} open={isOpen} autoHideDuration={5000} onClose={handleSnackClose}>
                                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                                    {message}
                                </Alert>
                            </Snackbar>
                            <DialogContentText>
                                {content}
                            </DialogContentText>
                            <TextField
                                autoFocus
                                multiline
                                margin="dense"
                                id="name"
                                label="Remarks"
                                type="text"
                                fullWidth
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onDismissMoveProfit}>Cancel</Button>
                            <LoadingButton loading={isSubmitting} onClick={onConfirmMoveProfit}>Save</LoadingButton>
                        </DialogActions>
                    </Dialog>

                </Box>

            </Dialog>


        </div >
    );
}
