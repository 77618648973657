// material
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// component
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(1.5, 0),
  color: theme.palette.secondary.darker,
  backgroundColor: theme.palette.secondary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.secondary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.secondary.dark, 0)} 0%, ${alpha(
    theme.palette.secondary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

PenaltiesPaid.propTypes = {
  value: PropTypes.number.isRequired
}
export default function PenaltiesPaid({ value }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Iconify icon="fa6-solid:cubes-stacked" width={24} height={24} />
      </IconWrapperStyle>
      <Typography  variant="h3">{fCurrency(value)}</Typography>
      <Typography  variant="subtitle2" sx={{ opacity: 0.72 }}>
        Penalties Balance
      </Typography>
    </RootStyle>
  );
}
