import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";

import {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../../table';
import { fNumber } from '../../../../utils/formatNumber';



const TABLE_HEAD = [
    { id: 'contributerId', label: 'ID', alignRight: false },
    { id: 'position', label: 'Slot No', alignRight: false },

    { id: 'name', label: 'Member Name', alignRight: false },
    { id: 'mobile', label: 'Mobile', alignRight: false },
    { id: 'has_received', label: 'Has Received?', alignRight: false },
    { id: 'complete', label: 'Payment Complete', alignRight: false },
    { id: 'query_date', label: 'Query Date', alignRight: false },
    { id: 'transaction_code', label: 'Payment Receipt', alignRight: false },
    { id: 'contribution_amount', label: 'Payment Amount', alignRight: false },
    { id: 'penalty_charge', label: 'Penalty Amount', alignRight: false },
    { id: 'contribution_account', label: 'Payment A/C', alignRight: false },
    { id: 'reference', label: 'Payment Remarks', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


ContributionsTable.propTypes = {
    contributions: PropTypes.array,
    childComponent: PropTypes.any
}

ContributionsTable.defaultProps = {
    contributions: []
}

export default function ContributionsTable({ contributions, childComponent }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const filteredContributions = applySortFilter(contributions, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredContributions.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contributions.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_contributions) => `${_contributions.contributerId}`.indexOf(query) !== -1
                || `${_contributions.mobile}`.indexOf(query) !== -1
                || `${_contributions.name}`.toLowerCase().indexOf(query) !== -1
                || `${_contributions.position}`.indexOf(query) !== -1
                || `${_contributions.transaction_code.toLowerCase()}`.indexOf(query) !== -1
                || `${_contributions.contribution_amount}`.indexOf(query) !== -1
                || `${_contributions.contribution_account}`.indexOf(query) !== -1
                || `${_contributions.reference !== null && _contributions.reference.toLowerCase()}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = contributions.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    return (
        <>
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={childComponent}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={contributions.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                            childComponent
                        />
                        <TableBody>
                            {filteredContributions && filteredContributions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { contributerId, mobile, name, has_received, position, query_date, transaction_code, contribution_amount, contribution_account, reference, penalty_charge, complete } = row;
                                    const isItemSelected = selected.indexOf(contributerId) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={contributerId}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{contributerId}</TableCell>
                                            <TableCell align="left">{position}</TableCell>
                                            <TableCell align="left">{name}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={has_received}
                                                />
                                            </TableCell>
                                            
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={complete}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{query_date}</TableCell>
                                            <TableCell align="left">{transaction_code}</TableCell>
                                            <TableCell align="left">{fNumber(contribution_amount)}</TableCell>
                                            <TableCell align="left">{fNumber(penalty_charge)}</TableCell>
                                            <TableCell align="left">{contribution_account}</TableCell>
                                            <TableCell align="left">{reference}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={contributions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}