import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Typography,
    Button,
    Alert,
    Snackbar,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { postData } from "../../../../utils/generalFunctions";
import { TableListHead, TableListToolbar } from '../../table';
import { DELETE_SUBSCRIBER } from "../../../../utils/urls";



const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Member Name', alignRight: false },
    { id: 'mobile', label: 'Mobile', alignRight: false },
    { id: 'national_number', label: 'Nationa ID', alignRight: false },
    { id: 'region', label: 'Region', alignRight: false },
    { id: 'contribution_account', label: 'Payment A/C', alignRight: false },
    { id: 'position', label: 'Slot No.', alignRight: false },
    { id: 'has_received', label: 'Has Received?', alignRight: false },
    { id: 'is_deleted', label: 'Is Active?', alignRight: false },
    { id: 'has_contributed', label: 'Has Contributed?', alignRight: false },
    { id: 'createdAt', label: 'Joined On', alignRight: false },
    { id: '', label: '', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


SubscribersTable.propTypes = {
    subscribers: PropTypes.array,
    setValue:PropTypes.func,
    getSubscribers:PropTypes.func,
}

SubscribersTable.defaultProps = {
    subscribers: []
}

export default function SubscribersTable({ subscribers ,setValue,getSubscribers}) {
    const [state, setState] = useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'right',
        severity: 'info',
        message: ''
    });
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [row, setRow] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState(false);
    const { severity, horizontal, vertical, message, isOpen } = state;

    const filteredSubscribers = applySortFilter(subscribers, getComparator(order, orderBy), filterName);

    const isGroupNotFound = filteredSubscribers.length === 0;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscribers.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_mebers) => `${_mebers.id}`.indexOf(query) !== -1
                || `${_mebers.mobile}`.indexOf(query) !== -1
                || `${_mebers.name}`.toLowerCase().indexOf(query) !== -1
                || `${_mebers.position}`.indexOf(query) !== -1
                || `${_mebers.region.toLowerCase()}`.indexOf(query) !== -1
                || `${_mebers.contribution_account}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = subscribers.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const deleteSubscriber = () => {
        setIsSubmitting(true)
        postData(`${DELETE_SUBSCRIBER}${row.id}`, {}, 'DELETE').then((response) => {
            setIsSubmitting(false)
            setConfirmOpen(false)
            if (response.status) {
                getSubscribers()
                setValue(1)
                setState({ ...state, isOpen: true, message: response.message, severity: 'success' })
            } else {
                setState({ ...state, isOpen: true, message: response.message, severity: 'danger' })
            }
        }).catch((error) => {
            setIsSubmitting(false)
            setConfirmOpen(false)
            setState({ ...state, isOpen: true, message: error.message, severity: 'danger' })
        })
    }

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };
    return (
        <>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={5000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '40%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <ConfirmDialog title="Please confirm" message={confirmMessage} open={confirmOpen} acceptText="Yes delete!" isSubmitting={isSubmitting} onConfirm={deleteSubscriber} onDismiss={() => setConfirmOpen(false)} onDecline={() => setConfirmOpen(false)} />
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                childComponent={<Typography>{filteredSubscribers.length}</Typography>}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={subscribers.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                            childComponent
                        />
                        <TableBody>
                            {filteredSubscribers && filteredSubscribers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, mobile, name, has_received, position, national_number, is_deleted, contribution_account, createdAt, region, has_contributed } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{name}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell align="left">{national_number}</TableCell>
                                            <TableCell align="left">{region}</TableCell>
                                            <TableCell align="left">{contribution_account}</TableCell>
                                            <TableCell align="left">{position}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={has_received}
                                                />
                                            </TableCell>

                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={!is_deleted}
                                                />
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={has_contributed}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{createdAt}</TableCell>
                                            <TableCell align="right">
                                                {!is_deleted ? <Button variant='text' size="small" onClick={() => {
                                                    setRow(row)
                                                    const message = `Dear admin, you are about to delete slot#${position} which belongs to ${name} with account number ${contribution_account}. When you confirm to delete this member will not be able to contribute to this account number (${contribution_account}) unless they are assigned another slot.\n\nClick 'Yes delete!' to delete it.`
                                                    setConfirmMessage(message)
                                                    setConfirmOpen(true)
                                                }}>Delete</Button> : <Button color='secondary' variant='text' size="small">No Action</Button>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={subscribers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}