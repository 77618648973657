import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Subscriptions from './pages/Subscriptions';
import ManagedChamas from './pages/ManagedChamas';
import NotFound from './pages/Page404';
import ChamaPayments from './pages/ChamaPayments';
import LipianaChama from './pages/LipianaChamaPayments';
import { getStorageItem } from './utils/generalFunctions'
import Withdraw from './pages/Withdraw';
import Recharge from './pages/Recharge';
import Transactions from './pages/Transactions';
import TulizaPayments from './pages/TulizaPayments';
import Faqs from './pages/Faqs';

// ----------------------------------------------------------------------


export default function Router() {
  const auth_token = getStorageItem('auth_token');
  let is_auth = false

  if (auth_token) {
    if (auth_token !== '') {
      const claims = JSON.parse(atob(auth_token.split('.')[1]))

      const countDownDate = new Date(new Date(claims.exp * 1000)).getTime();
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      const days_seconds = days * 86400
      if (!Number.isNaN(seconds)) {
        const sec = days_seconds + (seconds + (minutes * 60) + (hours * 60 * 60))
        if (sec > 60) {
          is_auth = true
        }
      }
    }
  }

  return useRoutes( 
    !is_auth ?
      [
        { path: '*', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'faqs', element: <Faqs /> },
      ] :
      [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },
            { path: 'subscriptions', element: <Subscriptions /> },
            { path: 'managed-chamas', element: <ManagedChamas /> },
            { path: 'statements', element: <Transactions /> },
            { path: 'tuliza-payments', element: <TulizaPayments /> },
          ]
        },
        {
          path: '/payments',
          element: <DashboardLayout />,
          children: [
            { path: 'chama-payments', element: <ChamaPayments /> },
            { path: 'lipiana-chama-payments', element: <LipianaChama /> },
            { path: 'recharge', element: <Recharge /> },
            { path: 'transfer-money', element: <Withdraw /> },
          ]
        },
        {
          path: '/',
          element: <LogoOnlyLayout />,
          children: [
            { path: '/',
            index:true, element: <Navigate to="/dashboard/app" /> },
            { path: 'login', element: <Login /> },
            { path: 'faqs', element: <Faqs /> },
            { path: 'register', element: <Register /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> }
          ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]);
}
