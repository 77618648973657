/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Card, CardContent } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { postData } from '../utils/generalFunctions';
import { FAQS } from '../utils/urls';

function parseFAQText(text) {
    const faqArray = [];
    const regex = /Q:\s*(.*?)\s*A:\s*(.*?)\s*(?=Q:|$)/gs;
    let match;

    while ((match = regex.exec(text)) !== null) {
        const question = match[1].trim();
        const answer = match[2].trim();
        faqArray.push({ question, answer });
    }

    return faqArray;
}


function Faqs() {
    const [faqData, setFaq] = useState([])
    useEffect(() => {
        postData(FAQS, null, 'GET').then(faqs => {

            const arr = parseFAQText(faqs)
            
            setFaq(arr)
        })
    }, [])
    return (
        <Container maxWidth="lg" style={{ marginTop: '50px' }}>
            <Typography variant="h2" align="center" gutterBottom>
                Pamoja FAQs
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {faqData.map((faq, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h5">{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Faqs;
