/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
import { alpha, styled } from '@mui/material/styles';

import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

AppTotalTuliza.propTypes = {
    schedules: PropTypes.array
}
export default function AppTotalTuliza({ schedules }) {
  const [state, setState] = React.useState({
    TOTAL: 0
  });

  useEffect(() => {
    mapTrx()
  },[schedules])

  const mapTrx = () => {
    let total = 0
     schedules && schedules.map((schedule) => {
      total += schedule.balance
      return schedule
    })


    setState({...state,TOTAL:total})
  }
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Iconify icon="akar-icons:schedule" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fCurrency(state.TOTAL)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Scheduled Payments (Tuliza)
      </Typography>
    </RootStyle>
  );
}
