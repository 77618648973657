/* eslint-disable array-callback-return */
import * as React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import Backdrop from '@mui/material/Backdrop';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    Box,
    TextField,
    FormControlLabel,
    Alert,
    FormControl,
    RadioGroup,
    Radio,
    Snackbar,
    Grid,
    Card,
    Container,
    Typography,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
    MenuItem, InputAdornment,
    ListItemText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import { getStorageItem, postData } from '../utils/generalFunctions';
import { TRANSFER_MONEY, BANKS, STATEMENT_URL, GET_TRANSFER_COST, FUNDRAISING } from '../utils/urls';
import ConfirmDialog from '../components/ConfirmDialog';

Withdraw.propTypes = {
    callbackFunc: PropTypes.func,
    typeValue: PropTypes.string,
    referenceId: PropTypes.number,
    savings_id: PropTypes.number
};

Withdraw.defaultProps = {
    callbackFunc: null,
    typeValue: 'personal',
    referenceId: 0,
    savings_id: 0
};
export default function Withdraw({ typeValue, callbackFunc, referenceId, savings_id }) {
    const [message, setMessage] = React.useState('')
    const [option, setOption] = React.useState('')
    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [statement, setStatement] = useState({})
    const [banks, setBanks] = React.useState([])
    const [methods, setMethods] = React.useState([])

    const schema = Yup.object().shape({
        mobile: Yup.string()
            .min(10, 'Mobile number is invalid')
            .max(12, 'Mobile number is invalid')
            .when("channel", {
                is: '1',
                then: Yup.number('Provide a valid mobile number')
                    .required('Provide a valid mobile number')
            })
            .notRequired('Mobile number is a mandatory field'),
        amount: Yup.number()
            .min(1, 'Minimum amount is KES1')
            .max(Math.floor(statement.savings_account && statement.savings_account.account_balance), `${typeValue === 'personal' ? 'Your' : 'M-Chango account'} balance is KES${Math.floor(statement.savings_account && statement.savings_account.account_balance)}`)
            .required('Withdrawal amount is a mandatory field'),
        reference: Yup.string()
            .min(1, 'Too short!')
            .max(200, 'Too long!')
            .required('Payment reference is a mandatory field (write anything important about this transaction)'),
        bankCode: Yup.string().when("channel", {
            is: '2',
            then: Yup.number('Select a destination bank')
                .required('Select a destination bank')
        }).notRequired(),
        account_number: Yup.string().min(4, 'Provide a valid account number')
            .when("channel", {
                is: '2' || '3',
                then: Yup.number('Provide a valid account number')
                    .required('Provide a valid account number')
            }).notRequired(),
        channel: Yup.number('Withdrawal channel is required'),
        member_name: Yup.string().when("channel", {
            is: '2',
            then: Yup.number('Please provide a valid member name.')
                .required('Please provide a valid member name.')
        }).notRequired(),
    });
    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error'
    });


    const formik = useFormik({
        initialValues: {
            mobile: '',
            amount: '',
            reference: '',
            channel: '1',
            member_name: '',
            account_number: '',
            bankCode: '',
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                values.savings_id = typeValue === 'personal' ? getStorageItem('savings_id') : savings_id
                postData(TRANSFER_MONEY, values)
                    .then(data => {
                        if (data.status) {
                            if (callbackFunc) {
                                callbackFunc()
                            }
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'success' });
                            formik.resetForm()
                            formik.setSubmitting(false)
                            toggleOpen()
                        } else {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'error' });
                        }
                    });
            } catch (error) {
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, horizontal, vertical, isOpen } = state;


    const isMountedRef = useIsMountedRef();

    const loadData = useCallback(async () => {
        try {
            if (isMountedRef.current) {

                if (typeValue === 'personal') {
                    await postData(STATEMENT_URL, null, 'GET')
                        .then(data => {
                            if (data.status) {
                                setStatement(data);
                            }
                        });
                } else {
                    await postData(`${FUNDRAISING}/${referenceId}`, null, 'GET')
                        .then(response => {
                            if (response.status) {
                                const { item } = response
                                setStatement({ savings_account: item });
                            }
                        });
                }



                await postData(BANKS, {}, 'GET').then((response) => {
                    try {
                        setBanks(response.bank_list)
                        setMethods(response.withdrawal_options)
                    } catch (error) {
                        console.log(error);
                    }
                })

            }

        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData();
    }, [loadData]);


    const [open, setOpen] = React.useState(false)
    const onConfirm = () => {
        formik.submitForm()
    }
    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };


    const toggleOpen = async () => {
        if (open) {
            setOpen(!open)
        } else {
            const option = filter(methods, p => `${p.id}` === values.channel)[0].name
            setOption(option)
            values.savings_id = typeValue === 'personal' ? getStorageItem('savings_id') : savings_id
            await postData(GET_TRANSFER_COST, values)
                .then(response => {
                    if (response.status) {
                        setConfirmMessage(response.data.message)
                        const errorValues = Object.values(formik.errors)
                        if (errorValues.length !== 0) {
                            return errorValues.map((error) => {
                                setMessage(error)
                                setState({ ...state, isOpen: true, severity: 'error' });
                            })

                        }

                        if (!formik.dirty) {
                            setMessage("All fields are required")
                            setState({ ...state, isOpen: true, severity: 'error' });
                        } else {
                            setOpen(!open)
                        }
                    } else {
                        formik.setErrors({ account_number: response.message })
                        setMessage(response.message)
                        setState({ ...state, isOpen: true, severity: 'error' });
                    }
                });


        }
    }

    const ITEM_HEIGHT = 60;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const renderBankFields = (
        <>
            <FormControl >
                <InputLabel id="bank-destination-label">Destination Bank</InputLabel>
                <Select

                    labelId="bank-destination-label"
                    id="bank-destination"
                    {...getFieldProps('bankCode')}
                    input={<OutlinedInput label="Destination Bank" />}
                    renderValue={(value) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            <Chip key={value} label={banks.filter(p => p.code === value)[0].name} />
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value="">
                        SELECT A DESTINATION BANK
                    </MenuItem>
                    {banks && banks.map(({ code, name }) => (
                        <MenuItem key={code} value={code}>
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                autoComplete="account_number"
                type="text"
                label={`${values.bankCode && banks.filter(p => p.code === values.bankCode)[0].name} A/C`}
                {...getFieldProps('account_number')}
                error={Boolean(touched.account_number && errors.account_number)}
                helperText={touched.account_number && errors.account_number}
            />


        </>
    )

    return (
        <Page title='Transfer Funds'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <ConfirmDialog title={`Transfer Money via ${option}?`} open={open} acceptText='Proceed' showDismiss={false} cancelText='Cancel!' isSubmitting={isSubmitting}
                    message={confirmMessage}
                    onConfirm={onConfirm} onDismiss={toggleOpen} onDecline={toggleOpen} />
            </Backdrop>

            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={20000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Container maxWidth="lg">

                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <Stack direction="column" mr={5} ml={5} mt={5} mb={5}>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant='body1'>Transfer money to?</Typography>
                            </Box>
                            <FormikProvider value={formik}>
                                <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={3}>
                                        <FormControl>
                                            {/* <FormLabel id="payment_channel">Transfer via?</FormLabel> */}
                                            <RadioGroup
                                                row
                                                aria-labelledby="payment_channel"
                                                name="row-radio-buttons-channel"
                                                {...getFieldProps('channel')}
                                            >
                                                {
                                                    methods && methods.map(({ id, position, name }) => <FormControlLabel key={position} value={id} control={<Radio />} label={name} />)
                                                }

                                            </RadioGroup>
                                        </FormControl>


                                        <TextField
                                            fullWidth
                                            autoComplete="amount"
                                            type="text"
                                            label="Transaction amount"
                                            {...getFieldProps('amount')}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Chip label='KES' />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <Stack spacing={3} style={{ display: values.channel !== '2' ? 'none' : undefined }}>
                                            {renderBankFields}
                                        </Stack>


                                        <TextField
                                            style={{ display: values.channel === '3' || values.channel === '2' ? 'none' : undefined }}
                                            fullWidth
                                            autoComplete="mobile"
                                            type="text"
                                            label='Telephone number'
                                            {...getFieldProps('mobile')}
                                            error={Boolean(touched.mobile && errors.mobile)}
                                            helperText={touched.mobile && errors.mobile}
                                        />

                                        <TextField
                                            style={{ display: values.channel !== '3' ? 'none' : undefined }}
                                            fullWidth
                                            autoComplete="account_number"
                                            type="text"
                                            label='Wallet A/C'
                                            {...getFieldProps('account_number')}
                                            error={Boolean(touched.account_number && errors.account_number)}
                                            helperText={touched.account_number && errors.account_number}
                                        />
                                        <TextField
                                            style={{ display: values.channel !== '2' ? 'none' : undefined }}
                                            fullWidth
                                            autoComplete="member_name"
                                            type="text"
                                            label='A/C Holder name'
                                            {...getFieldProps('member_name')}
                                            error={Boolean(touched.member_name && errors.member_name)}
                                            helperText={touched.member_name && errors.member_name}
                                        />


                                        <TextField
                                            multiline
                                            fullWidth
                                            autoComplete="reference"
                                            type="text"
                                            label="Payment reference (a note e.g. School fee)"
                                            {...getFieldProps('reference')}
                                            error={Boolean(touched.reference && errors.reference)}
                                            helperText={touched.reference && errors.reference}
                                        />
                                    </Stack>

                                    <Stack direction='row' spacing={2} mt={2} alignItems="end">
                                        <LoadingButton
                                            autoFocus
                                            size="medium"
                                            variant="contained"
                                            loading={isSubmitting}
                                            onClick={() => formik.resetForm()}
                                        >
                                            Clear
                                        </LoadingButton>

                                        <LoadingButton
                                            autoFocus
                                            size="medium"
                                            onClick={toggleOpen}
                                            color='secondary'
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Transfer Money
                                        </LoadingButton>
                                    </Stack>

                                </Form>
                            </FormikProvider>
                        </Stack>
                    </Card>
                </Grid>
            </Container>
        </Page>
    );
}
