/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import useIsMountedRef from 'use-is-mounted-ref';
import { filter } from "lodash";
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
// material
import {
    Stack,
    Button,
    Typography,
    Card,
    Tabs,
    Tab,
    Alert,
    Snackbar
} from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { a11yProps, postData } from '../../../../utils/generalFunctions';
import ManagersTable from '../@tables/Managers';
import TabPanel from '../TabPanel';
import CreateManager from './create'
import { ADMINS } from '../../../../utils/urls';




export default function GroupManagers({ getGroupStatus }) {
    const data = useSelector(state => state.selectedGroup)
    const admins = useSelector(state => state.group_admins)
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'info',
        message: ''
    });
    const isMountedRef = useIsMountedRef();

    const getManagers = React.useCallback(async () => {
        try {
            if (isMountedRef.current) {
                postData(`${ADMINS}/${data.group_id}`, {}, 'GET').then((response) => {
                    if (response.status) {
                        dispatch({ type: 'set', group_admins: response.admins })
                    } else {
                        dispatch({ type: 'set', group_admins: [] })
                    }
                })
            }
        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);

    React.useEffect(() => {
        getManagers()
    }, [getManagers])

    const openCreate = () => {
        setOpen(true)
    }

    const { severity, horizontal, vertical, message, isOpen } = state;
    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'info' });
    };
    return (
        <Card>
            <CreateManager open={open} setOpen={setOpen} getManagers={getManagers} />
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={5000000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '50%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
                <Typography variant="body2" gutterBottom>Group Members</Typography>
                <Stack spacing={1} direction="row">
                    <Button
                        sx={{ display: value === 0 ? '' : 'none' }}
                        variant="contained"
                        onClick={openCreate}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        create
                    </Button>
                    <Button
                        variant="text"
                        onClick={getManagers}
                        startIcon={<Iconify icon="eva:refresh-fill" />}
                    >
                        refresh
                    </Button>

                </Stack>
            </Stack>

            <Tabs selectionFollowsFocus
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Payments Management"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab sx={{ alignSelf: 'start' }} label="Active Managers" {...a11yProps(0)} />
                <Tab sx={{ alignSelf: 'start' }} label="Inactive Managers" {...a11yProps(1)} />
                <Tab sx={{ alignSelf: 'start' }} label="Signatories" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <ManagersTable getManagers={getManagers} setValue={setValue} managers={filter(admins, (_item) => _item.active)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ManagersTable getManagers={getManagers} setValue={setValue} managers={filter(admins, (_item) => !_item.active)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ManagersTable getManagers={getManagers} setValue={setValue} managers={filter(admins, (_item) => _item.is_signatory)} />
            </TabPanel>
        </Card>
    );
}
