

export function titleCase(string) {
    const sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i += 1) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return "sentence";
}
export const getToday = () => {
    const contribution_date = new Date()
    const dd = String(contribution_date.getDate()).padStart(2, '0');
    const mm = String(contribution_date.getMonth() + 1).padStart(2, '0'); 
    const yyyy = contribution_date.getFullYear();

    return `${yyyy}-${mm}-${dd}`
}

export function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function getStorageItem(name) {
    const item = localStorage.getItem(name);

    if (item === null || item === undefined || item === "undefined") {
        return "";
    }

    return item;
}

export function setStorageItem(name, value) {
    return localStorage.setItem(name, value);
}

export function removeStorageItem(cname) {
    return localStorage.removeItem(cname);
}

// Example POST method implementation:
export async function postData(url = '', data = {}, method = 'POST') {
    // Default options are marked with *
    const token = getStorageItem('auth_token')
    const response = await fetch(url, {
        method, // *GET, POST, PUT, DELETE, etc.
        // mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: method === 'POST' || method === 'PUT' || method === 'PATCH' ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export function refreshToken() {

}


