/* eslint-disable array-callback-return */
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    Box,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Alert, DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    MenuItem,
    Select,
    OutlinedInput,
    ListItemText,
    InputLabel,
    Chip,
    Tooltip,
    Snackbar
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { HYBRID_GROUP_CREATE, FUNDRAISING } from '../../../utils/urls';
import Iconify from '../../../components/Iconify';
import { postData } from '../../../utils/generalFunctions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const schema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    name: Yup.string()
        .when("type", {
            is: 'table' || 'mgr',
            then: Yup.string('This field is required')
                .min(2, 'Too short!')
                .required('This field is required')
        }).notRequired(),
    budget: Yup.number()
        .when("type", {
            is: 'fundraiser',
            then: Yup.number('Budget amount is required')
                .min(0, 'invalid amount')
                .required('Budget amount is required')
        }).notRequired(),
    expenses: Yup.number()
        .min(0, 'invalid amount')
        .notRequired(),
    deadline: Yup.string()
        .notRequired(),
    current_offline_contributions: Yup.number()
        .min(0, 'invalid amount')
        .notRequired(),
    description: Yup.string()
        .when("type", {
            is: 'fundraiser',
            then: Yup.string('M-Chango description is required')
                .min(3, 'Please provide a valid M-Chango description')
                .required('M-Chango description is required')
        }).notRequired(),
    introduction: Yup.string().notRequired(),
    contribution_amount: Yup.number()
        .when("type", {
            is: 'table' || 'mgr',
            then: Yup.number('Contribution amount is required')
                .min(0, 'Minimum amount is KES50')
                .max(300000, 'Maximum limit is KES300,0000')
                .required('Contribution amount is required')
        }).notRequired(),
    service_charge_amount: Yup.number()
        .min(0, 'Minimum amount is KES0')
        .required('Service charge amount is required'),
    duration: Yup.string()
        .when("type", {
            is: 'table' || 'mgr',
            then: Yup.string('Provide an interest rate')
                .required('Choose the frequency of contribution')
        }).notRequired(),
    has_whatsapp: Yup.boolean().notRequired(),
    charge_fines: Yup.boolean().notRequired(),
    charge_on_percent: Yup.boolean().notRequired(),
    group_image_url: Yup.string().notRequired(),
    interest_rate: Yup.number()
        .min(0, 'Provide a valid interest rate').when("type", {
            is: 'table',
            then: Yup.number('Provide an interest rate')
                .required('Provide an interest rate')
        }).notRequired(),
    start_hour: Yup.number().min(9, 'It`s fair to charge fines past 9am')
        .max(23, 'The maximum hour you can use is 23')
        .when("charge_fines", {
            is: true,
            then: Yup.number('Please provide a valid hour')
                .required('Please provide a valid hour')
        }).notRequired(),
    fine_percent: Yup.number().notRequired(),
    fine_charge: Yup.number().notRequired(),
    type: Yup.string('A/C type is required').required(),
    mode: Yup.string('Fine mode is required')
        .when("charge_fines", {
            is: true,
            then: Yup.string('Fine Mode is required if you check Penalty Checker')
                .required('Fine Mode is on please choose an option')
        }).notRequired(),
    whatsapp_invite_link: Yup.string('WhatsApp Link is required')
        .when("has_whatsapp", {
            is: true,
            then: Yup.string('WhatsApp Link is required if you check Integration Checker')
                .required('WhatsApp Link is required')
        }).notRequired(),
    fine_value: Yup.number('Fine value is required')
        .when("charge_fines", {
            is: true,
            then: Yup.number('Fine value is required if you check Penalty Checker')
                .min(1, 'This field is required')
                .required('Charges are on is on please choose an option')
        }).notRequired(),
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

CreateGroupDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    default_cron_str: PropTypes.string,
    refreshSystem: PropTypes.func
};
export default function CreateGroupDialog(props) {
    const { open, setOpen, ...other } = props
    const [message, setMessage] = React.useState('')

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error',
        frequencyValue: 'daily',
        dayOfWeek: ['MONDAY'],
        dayOfMonth: [],
        monthOY: [],
        typeValue: 'fundraiser'
    });


    const formik = useFormik({
        initialValues: {
            name: '',
            contribution_amount: 0,
            service_charge_amount: 0,
            duration: 'daily',
            has_whatsapp: true,
            whatsapp_invite_link: '',
            group_image_url: '',
            type: 'fundraiser',
            interest_rate: 0,
            charge_fines: false,
            fine_percent: '',
            charge_on_percent: false,
            fine_charge: '',
            start_hour: 21,
            mode: 'percent',
            fine_value: 1,
            budget: '',
            current_offline_contributions: 0,
            expenses: 0,
            introduction: '',
            description: '',
            deadline: new Date()
        },
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                if (values.type === 'fundraiser') {
                    const data = {
                        deadline: values.deadline,
                        description: values.description,
                        introduction: values.introduction,
                        purpose: values.description,
                        budget: values.budget,
                        current_offline_contributions: values.current_offline_contributions,
                        expenses: values.expenses,
                        whatsapp_invite_link: values.whatsapp_invite_link
                    }
                    postData(`${FUNDRAISING}/create`, data)
                        .then(data => {
                            if (data.status) {
                                setMessage(data.message)
                                setState({ ...state, isOpen: true, severity: 'success' });
                                formik.resetForm()
                                formik.setSubmitting(false)
                                formik.isValidating = false
                                props.refreshSystem()
                                setOpen(false)
                            } else {
                                formik.setSubmitting(false)
                                formik.isValidating = false
                                setMessage(data.message)
                                setState({ ...state, isOpen: true, severity: 'error' });
                            }
                        }).catch(() => {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                        });
                } else {
                    values.cronJob = `${other.default_cron_str} `
                    if (values.duration === 'daily') {
                        values.cronJob += dayOfMonth.join(',')

                        values.cronJob += '* * *'
                    }

                    if (values.duration === 'monthly') {
                        values.cronJob += dayOfMonth.join(',')

                        values.cronJob += ' * *'
                    }

                    if (values.duration === 'yearly') {
                        values.cronJob += '1 '
                        let monthsNo = []
                        monthsNo = monthOY.map((p) => monthNum[p])

                        if (monthsNo.length === 12) {
                            values.cronJob += '* *'
                        } else {
                            values.cronJob += monthsNo.join(',')
                            values.cronJob += ' *'
                        }

                    }

                    if (values.duration === 'weekly') {
                        let daysNo = []

                        if (dayOfWeek.length !== 7) {
                            daysNo = dayOfWeek.map((p) => dayNum[p])

                            values.cronJob += '* * '
                            values.cronJob += daysNo.join(',')
                        } else {
                            values.cronJob += dayOfMonth.join(',')

                            values.cronJob += ' * * *'
                        }

                    }

                    postData(HYBRID_GROUP_CREATE, values)
                        .then(data => {
                            if (data.status) {
                                setMessage(data.message)
                                setState({ ...state, isOpen: true, severity: 'success' });
                                formik.resetForm()
                                formik.setSubmitting(false)
                                formik.isValidating = false
                                props.refreshSystem()
                                setOpen(false)
                            } else {
                                formik.setSubmitting(false)
                                formik.isValidating = false
                                setMessage(data.message)
                                setState({ ...state, isOpen: true, severity: 'error' });
                                // formik.isValid = false
                            }
                        }).catch(() => {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                        });;
                }

            } catch (error) {
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, dayOfWeek, horizontal, vertical, dayOfMonth, monthOY, isOpen } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };

    const handleClose = () => {
        setOpen(false);
    };




    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleDOWChange = (event) => {
        const {
            target: { value },
        } = event;
        setState({ ...state, dayOfWeek: typeof value === 'string' ? value.split(',') : value })

    };

    const handleDOMChange = (event) => {
        const { target: { value }, } = event;
        setState({ ...state, dayOfMonth: typeof value === 'string' ? value.split(',') : value })

    };

    const handleMOYChange = (event) => {
        const {
            target: { value },
        } = event;
        setState({ ...state, monthOY: typeof value === 'string' ? value.split(',') : value })

    };

    const dayNum = {
        'MONDAY': 1,
        'TUESDAY': 2,
        'WEDNESDAY': 3,
        'THURSDAY': 4,
        'FRIDAY': 5,
        'SATURDAY': 6,
        'SUNDAY': 7,
    }
    const [value, setValue] = React.useState(new Date());

    const monthNum = {
        'January': 1, 'February': 2, 'March': 3, 'April': 4, 'May': 5, 'June': 6, 'July': 7, 'August': 8, 'September': 9, 'October': 10, 'November': 11, 'December': 12
    }

    const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

    const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1)

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const handleChange = (newValue) => {
        formik.setFieldValue('deadline', newValue)
        setValue(newValue);
    };


    return (
        <div>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth='md'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {values.type === 'fundraiser' ? 'Fundraising/M-Chango Account' : 'MGR/TB Contributions'}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Alert style={{ display: message === "" ? 'none' : undefined }} severity={severity} sx={{ marginBottom: 2 }}>{message}</Alert>
                    <FormikProvider value={formik}>
                        <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>

                            <Stack direction="row" alignItems="center" justifyContent="stretch" sx={{ my: 2 }}>
                                <FormControl>
                                    <FormLabel id="group_type_id">Group A/C Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="group_type_id"
                                        name="row-radio-buttons-group"
                                        {...getFieldProps('type')}
                                    >
                                        <FormControlLabel value="fundraiser" control={<Radio />} label="M-Chango Account" />
                                        <FormControlLabel value="mgr" control={<Radio />} label="Merry Go Round" />
                                        <FormControlLabel value="table" control={<Radio />} label="Table Banking" />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>


                            <Stack mb={1} spacing={2} sx={{ display: values.type !== 'fundraiser' ? undefined : 'none' }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        autoComplete="name"
                                        type="text"
                                        label="Group name"
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Contribution amount"
                                        {...getFieldProps('contribution_amount')}
                                        error={Boolean(touched.contribution_amount && errors.contribution_amount)}
                                        helperText={touched.contribution_amount && errors.contribution_amount}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Service charge amount"
                                        {...getFieldProps('service_charge_amount')}
                                        error={Boolean(touched.service_charge_amount && errors.service_charge_amount)}
                                        helperText={touched.service_charge_amount && errors.service_charge_amount}
                                    />
                                </Stack>


                                <Stack direction="row" justifyContent="stretch" sx={{ my: 2 }}>
                                    <FormControl>
                                        <FormLabel id="often_contribute_id">How often do you contribute?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="often_contribute_id"
                                            name="row-radio-buttons-group"
                                            {...getFieldProps('duration')}
                                        >
                                            <FormControlLabel value="daily" control={<Radio sx={{
                                                // color: 'red',
                                                // '&.Mui-checked': {
                                                //     color: 'indigo',
                                                // },
                                            }} />} label="Daily" />
                                            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                                            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>

                                <FormControl style={{ display: values.duration !== 'weekly' ? 'none' : undefined }} >
                                    <InputLabel id="demo-multiple-checkbox-label">Day of the week</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={dayOfWeek}
                                        onChange={handleDOWChange}
                                        input={<OutlinedInput label="Day of the week" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {daysOfWeek.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox checked={dayOfWeek.indexOf(day) > -1} />

                                                <ListItemText primary={day} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl style={{ display: values.duration !== 'monthly' ? 'none' : undefined }} >
                                    <InputLabel id="demo-multiple-checkbox-label">Day of the month</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={dayOfMonth}
                                        onChange={handleDOMChange}
                                        input={<OutlinedInput label="Day of the month" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}>
                                        {daysInMonth.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox checked={dayOfMonth.indexOf(day) > -1} />

                                                <ListItemText primary={day} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl style={{ display: values.duration !== 'yearly' ? 'none' : undefined }} >
                                    <InputLabel id="demo-multiple-checkbox-label">Month of the year</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={monthOY}
                                        onChange={handleMOYChange}
                                        input={<OutlinedInput label="Month of the year" />}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip variant='outlined' key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {months.map((month) => (
                                            <MenuItem key={month} value={month}>
                                                <Checkbox checked={monthOY.indexOf(month) > -1} />

                                                <ListItemText primary={month} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    disabled={!values.has_whatsapp}
                                    fullWidth
                                    autoComplete="whatsapp_invite_link"
                                    type='text'
                                    label="WhatsApp Invite Link"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.has_whatsapp} {...getFieldProps('has_whatsapp')} />}
                                                    label="Do you have WhatsApp Group?"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(touched.whatsapp_invite_link && errors.whatsapp_invite_link)}
                                    helperText={touched.whatsapp_invite_link && errors.whatsapp_invite_link}
                                    {...getFieldProps('whatsapp_invite_link')}
                                />
                                <FormControlLabel
                                    sx={{ display: values.type === 'fundraiser' ? 'none' : undefined }}
                                    control={<Checkbox {...getFieldProps('charge_fines')} />}
                                    label="Penalize on late payments?"
                                />

                                <FormControl style={{ display: !values.charge_fines ? 'none' : undefined }}>
                                    <FormLabel id="mode">Fine Mode</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="mode"
                                        name="row-radio-buttons-group"
                                        {...getFieldProps('mode')}
                                    >
                                        <FormControlLabel value="percent" control={<Radio color="success" />} label="Fine by percent" />
                                        <FormControlLabel value="fixed" control={<Radio color="secondary" />} label="Fine by fixed amount" />
                                    </RadioGroup>
                                </FormControl>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        style={{ display: !values.charge_fines ? 'none' : undefined }}
                                        label={`${values.mode === 'fixed' ? 'Penalty Fixed Charge' : 'Penalty Percentage'}`}
                                        error={Boolean(touched.fine_value && errors.fine_value)}
                                        helperText={touched.fine_value && errors.fine_value}
                                        {...getFieldProps('fine_value')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Chip label={values.mode.toUpperCase()} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <Tooltip placement="top-start" title="Please enter the contribution deadline hour,this will trigger fines charging past the hour. Use 24hr system e.g 21">
                                        <TextField
                                            fullWidth
                                            sx={{ display: !values.charge_fines ? 'none' : undefined }}
                                            label='Contribution Deadline (hr)'
                                            error={Boolean(touched.start_hour && errors.start_hour)}
                                            helperText={touched.start_hour && errors.start_hour}
                                            {...getFieldProps('start_hour')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Chip label='hr' />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Tooltip>

                                    <TextField
                                        fullWidth
                                        style={{ display: values.type === 'mgr' ? 'none' : undefined }}
                                        label='Interest rate'
                                        error={Boolean(touched.interest_rate && errors.interest_rate)}
                                        helperText={touched.interest_rate && errors.interest_rate}
                                        {...getFieldProps('interest_rate')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Chip label='PERCENT' />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>



                            </Stack>

                            <Stack mb={1} spacing={2} justifyContent="stretch" sx={{ display: values.type === 'fundraiser' ? undefined : 'none' }}>
                                <TextField
                                    fullWidth
                                    autoComplete="description"
                                    type="text"
                                    label="M-Chango Purpose"
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Budget amount"
                                        {...getFieldProps('budget')}
                                        error={Boolean(touched.budget && errors.budget)}
                                        helperText={touched.budget && errors.budget}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Current total expenses"
                                        {...getFieldProps('expenses')}
                                        error={Boolean(touched.expenses && errors.expenses)}
                                        helperText={touched.expenses && errors.expenses}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Cash at hand (collected offline)"
                                        {...getFieldProps('current_offline_contributions')}
                                        error={Boolean(touched.current_offline_contributions && errors.current_offline_contributions)}
                                        helperText={touched.current_offline_contributions && errors.current_offline_contributions}
                                    />
                                </Stack>
                                <TextField
                                    disabled={!values.has_whatsapp}
                                    fullWidth
                                    autoComplete="whatsapp_invite_link"
                                    type='text'
                                    label="WhatsApp Invite Link"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.has_whatsapp} {...getFieldProps('has_whatsapp')} />}
                                                    label="Do you have WhatsApp Group?"
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(touched.whatsapp_invite_link && errors.whatsapp_invite_link)}
                                    helperText={touched.whatsapp_invite_link && errors.whatsapp_invite_link}
                                    {...getFieldProps('whatsapp_invite_link')}
                                />

                                <Stack direction={{ sm: 'row', xs: 'column' }} justifyContent='space-between' spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label='Deadline'
                                            value={value}
                                            minDateTime={new Date()}
                                            ampm
                                            ampmInClock
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>

                                    <TextField
                                        fullWidth
                                        autoComplete="introduction"
                                        type="text"
                                        multiline
                                        label="Introduction Message (will be sent on WhatsApp)"
                                        {...getFieldProps('introduction')}
                                        error={Boolean(touched.introduction && errors.introduction)}
                                        helperText={touched.introduction && errors.introduction}
                                    />
                                </Stack>

                            </Stack>
                            <DialogActions>
                                {/* <LoadingButton
                                    autoFocus
                                    size="medium"
                                    onClick={() => {
                                        console.log(formik.errors);
                                    }}
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Test
                                </LoadingButton> */}
                                <LoadingButton
                                    autoFocus
                                    size="medium"
                                    type="reset"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Clear
                                </LoadingButton>
                                <LoadingButton
                                    autoFocus
                                    color='secondary'
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Create
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    </FormikProvider>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}
