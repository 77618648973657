/* eslint-disable array-callback-return */
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    IconButton,
    Alert, DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Snackbar
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FUNDRAISING } from '../../../../utils/urls';
import Iconify from '../../../../components/Iconify';
import { postData } from '../../../../utils/generalFunctions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const schema = Yup.object().shape({
    amount: Yup.number()
        .min(100, 'The minimum pledge amount is KES100')
        .required('Please provide a pledge amount'),
    description: Yup.string()
        .notRequired(),
    full_name: Yup.string()
        .required("Name of the contributer is required*"),
    mobile_number: Yup.string()
        .min(10, 'Invalid telephone number')
        .required("Telphone number of the contributer is required*"),
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

CreatePledge.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    fundraiser: PropTypes.object,
    refreshSystem: PropTypes.func
};
export default function CreatePledge(props) {
    const { open, setOpen, fundraiser, refreshSystem, ...other } = props
    const [message, setMessage] = React.useState('')

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'left',
        severity: 'error',
        frequencyValue: 'daily',
        dayOfWeek: ['MONDAY'],
        dayOfMonth: [],
        monthOY: [],
        typeValue: 'fundraiser'
    });


    const formik = useFormik({
        initialValues: {
            fundraiserId: fundraiser.id,
            amount: '',
            description: `${fundraiser.description} PLEDGE`,
            full_name: '',
            mobile_number: ''
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                postData(`${FUNDRAISING}/create_pledge`, values)
                    .then(data => {
                        if (data.status) {
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'success' });
                            formik.resetForm()
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            refreshSystem()
                            setOpen(false)
                        } else {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'error' });
                        }
                    }).catch(() => {
                        formik.setSubmitting(false)
                        formik.isValidating = false
                    });

            } catch (error) {
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, dayOfWeek, horizontal, vertical, dayOfMonth, monthOY, isOpen } = state;

    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };

    const handleClose = () => {
        setOpen(false);
    };




    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleDOWChange = (event) => {
        const {
            target: { value },
        } = event;
        setState({ ...state, dayOfWeek: typeof value === 'string' ? value.split(',') : value })

    };

    const handleDOMChange = (event) => {
        const {
            target: { value },
        } = event;
        setState({ ...state, dayOfMonth: typeof value === 'string' ? value.split(',') : value })

    };

    const handleMOYChange = (event) => {
        const {
            target: { value },
        } = event;
        setState({ ...state, monthOY: typeof value === 'string' ? value.split(',') : value })

    };


    const [value, setValue] = React.useState(new Date());


    return (
        <div>
            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth='md'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    CREATE A PLEDGE
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Alert style={{ display: message === "" ? 'none' : undefined }} severity={severity} sx={{ marginBottom: 2 }}>{message}</Alert>
                    <FormikProvider value={formik}>
                        <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                            <Stack direction='column-reverse' spacing={4}>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Contributer name"
                                        {...getFieldProps('full_name')}
                                        error={Boolean(touched.full_name && errors.full_name)}
                                        helperText={touched.full_name && errors.full_name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Telephone no."
                                        {...getFieldProps('mobile_number')}
                                        error={Boolean(touched.mobile_number && errors.mobile_number)}
                                        helperText={touched.mobile_number && errors.mobile_number}
                                    />
                                </Stack>

                                <TextField
                                    fullWidth
                                    label="Pledge amount"
                                    {...getFieldProps('amount')}
                                    error={Boolean(touched.amount && errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                />

                                <TextField
                                    fullWidth
                                    label="Pledge Description"
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Stack>

                            <DialogActions>
                                <LoadingButton
                                    autoFocus
                                    size="medium"
                                    type="reset"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Clear
                                </LoadingButton>
                                <LoadingButton
                                    autoFocus
                                    color='warning'
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Pledge 
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    </FormikProvider>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}
