/* eslint-disable*/
import React, { useEffect, useState } from 'react'
import T from 'prop-types'
import { Button, Chip, Grid } from '@mui/material'
import { postData } from '../../../utils/generalFunctions'
import { CONTRIBUTION_DATES } from '../../../utils/urls'

ContributionDays.propTypes = {
    group: T.object
}

export default function ContributionDays({ group }) {
    const [data, setDates] = useState([])
    useEffect(() => {
        getContributionDays()
    }, [group])

    const getContributionDays = async () => {
        await postData(`${CONTRIBUTION_DATES}?groupId=${group?.id}`, {}, 'GET').then((response) => {
            if (response.status) {
                setDates(response.data)
            }
        })
    }
    return (
        <Grid container>
            <Grid item sm={12} lg={12}>
                <Button sx={{ mb: 4, float: 'right',color:'white' }} size='small' color='warning' variant='contained'>+ Contribution Day</Button>
            </Grid>

            {data.map(({ date }, index) => (
                <Grid item sm={6} key={index} lg={1} xl={1}>
                    <Chip label={date} key={index} sx={{ m: 1, cursor: 'grab' }} color={index === 0 ? 'info' : index === data.length - 1 ? 'error' : 'secondary'} variant={index === 0 ? 'filled' : 'outlined'} />
                </Grid>
            ))}
        </Grid>
    )
}
