// material
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { fCurrency, fShortenNumber } from '../../../utils/formatNumber';
// utils
// component
// import { getStorageItem } from 'src/utils/generalFunctions';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));


// ----------------------------------------------------------------------
// const account = getStorageItem('account_')
AppCurrentBalance.propTypes = {
  statement: PropTypes.object
}
export default function AppCurrentBalance({statement}) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Iconify icon="ic:baseline-account-balance-wallet" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fCurrency(statement.savings_account && statement.savings_account.account_balance)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Balance
      </Typography>
    </RootStyle>
  );
}
