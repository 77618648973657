/* eslint-disable-line no-unused-vars */
import { useDispatch } from 'react-redux';
import { filter } from 'lodash';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import { STATEMENT_URL } from '../utils/urls';
import Page from '../components/Page';
import { MemberShipChamas } from '../sections/@dashboard/subscriptions';
import { postData } from '../utils/generalFunctions';
// ----------------------------------------------------------------------

export default function Subscriptions() {
  const [statement, setStatement] = useState({});
  const [subscriptions, setSubscriptions] = useState([])

  const dispatch = useDispatch()

  const isMountedRef = useIsMountedRef();

  const getStatement = useCallback(async () => {
    try {
      await postData(STATEMENT_URL, null, 'GET')
        .then(data => {
          if (data.status) {
            if (isMountedRef.current) {
              setStatement(data);
              dispatch({ type: 'set', statement: data })

              createObject(data.subscriptions)
            }
          }
        });


    } catch (err) {
      console.log(err.message)
    }
  }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStatement();
  }, [getStatement]);


  const createObject = (data) => {
    let list = []
    list = data.map((subscription) => {
      const row = {
        id: subscription.id,
        group_name: subscription.group.name,
        account_type: subscription.group.account_type === 2 ? 'MGR' : 'TABLE BANKING',
        contribution_account: subscription.contribution_account,
        position: subscription.position,
        contribution_amount: subscription.group.contribution_amount + subscription.group.service_charge_amount,
        contribution_date: subscription.group.contribution_date,
        frequency: subscription.group.frequency,
        role: subscription.role.name,
        has_contributed: subscription.has_contributed,
        has_received: subscription.has_received,
        charge_fines: subscription.group.charge_fines,
        charge_on_percent: subscription.group.charge_on_percent,
        fine_percent: subscription.group.fine_percent,
        fine_charge: subscription.group.fine_charge,
        start_hour: subscription.group.start_hour,
        is_signatory: subscription.is_signatory,
        has_whatsapp: subscription.group.has_whatsapp,
        whatsapp_invite_link: `${subscription.group.whatsapp_invite_link.substr(0, 10)}...`,
        whatsapp_invite_copy: `${subscription.group.whatsapp_invite_link}`,
        status: '',
      }
      return row
    })

    setSubscriptions(list)
  }

  return (
    <Page title={`Chama: Subscriptions | ${statement.app_name && statement.app_name}`}>
      <Grid item xs={12} md={12} lg={12}>
        <MemberShipChamas tap={getStatement} subscriptions={subscriptions && filter(subscriptions, p => p.position !== 0)} />
      </Grid>
    </Page>
  );
}
