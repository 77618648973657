/* eslint array-callback-return: */

import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useIsMountedRef from 'use-is-mounted-ref';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../../../components/charts';

// ----------------------------------------------------------------------



export default function GroupTransfersGraph() {
  const state = useSelector(state => state.fundraiser)

  const [CHART_DATA, SET_CHART_DATA] = useState([])
  const [labels, SET_LABELS] = useState([])

  const isMountedRef = useIsMountedRef();

  const getGraphData = useCallback(async () => {
    try {

      const labels = []
      const setOne = []
      const setTwo = []
      if (state) {
        state.debitTransfers.map((record) => {
          labels.push(record.query_date)
          setTwo.push(record.amount.toFixed(2))
        })

        state.creditTransfers.map((record) => {
          if (!labels.includes(record.query_date)) {
            labels.push(record.query_date)
          }

          setOne.push(record.amount.toFixed(2))
        })
      }



      SET_LABELS(labels)
      SET_CHART_DATA([
        {
          name: 'Deposits',
          type: 'column',
          data: setOne
        },
        {
          name: 'Withdrawals',
          type: 'area',
          data: setTwo
        },
      ])
    } catch (err) {
      console.log(err.message)
    }
  }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getGraphData()
  }, [state])
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '50%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `KES${y.toFixed(0)}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Transfer Trends" subheader="Transfers in the last -210 days " />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={500} />
      </Box>
    </Card>
  );
}
