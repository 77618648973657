import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";

import {
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Alert,
    Snackbar
} from '@mui/material';
import { SIGNAPPROVAL } from "../../../../utils/urls";
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../../table';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';
import { fDateTime } from "../../../../utils/formatTime";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { postData } from "../../../../utils/generalFunctions";


const TABLE_HEAD = [
    { id: '', label: 'Actions' },
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'processor', label: 'A/C Provider', alignRight: false },
    { id: 'account_number', label: 'Payment A/C', alignRight: false },
    { id: 'membership_account', label: 'Membership A/C', alignRight: false },
    { id: 'member_name', label: 'Group Member', alignRight: false },
    { id: 'method', label: 'Payment Method', alignRight: false },
    { id: 'amount', label: 'Disbursement Amount', alignRight: false },
    { id: 'complete', label: 'Approval Complete', alignRight: false },
    { id: 'reference', label: 'Transfer reference', alignRight: false },
    { id: 'total_approvers', label: 'Approvers', alignRight: false },
    { id: 'total_disapprovers', label: 'Disapprovers', alignRight: false },
    { id: 'mobile', label: 'IPN/Payment Mobile', alignRight: false },
    { id: 'registered_mobile', label: 'Member Contact', alignRight: false },
    { id: 'to_receive_on', label: 'Expected Disbursement Time', alignRight: false },
    { id: 'createdAt', label: 'Created On', alignRight: false },
    { id: 'updatedAt', label: 'Updated On', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


ApprovalePage.propTypes = {
    approvals: PropTypes.array,
    setValue: PropTypes.func,
    getApprovals: PropTypes.func,
    getPayments: PropTypes.func,
}

ApprovalePage.defaultProps = {
    approvals: []
}

export default function ApprovalePage({ approvals, getApprovals, getPayments, setValue }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const filteredApprovals = applySortFilter(approvals, getComparator(order, orderBy), filterName);
    const isGroupNotFound = filteredApprovals.length === 0;
    const [state, setState] = useState({
        isOpen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error'
    });
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approvals.length) : 0;


    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_approval) => `${_approval.id}`.indexOf(query) !== -1 || `${_approval.mobile}`.indexOf(query) !== -1 || `${_approval.clientName.toLowerCase()}`.indexOf(query) !== -1 || `${_approval.member_no !== null && _approval.member_no.toLowerCase()}`.indexOf(query) !== -1);
        }

        return stabilizedThis.map((el) => el[0]);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = approvals.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const [open, setOpen] = useState(false)
    const [writeOpen, setWriteOpen] = useState(false)
    const [approval, setApproval] = useState(null)
    const [message, setMessage] = useState("")
    const [comment, setRemarks] = useState("")
    const [alert, setAlert] = useState("")
    const [isSubmitting, setSubmitting] = useState(false)

    const { severity, horizontal, vertical, isOpen } = state;
    const onApprove = async () => {
        const data = {
            requestId: approval.id,
            comment,
            choice: 1
        }
        setSubmitting(true)

        setState({ ...state, isOpen: true, severity: 'warning' });
        setAlert("Processing please wait...")
        setTimeout(() => {
            postData(SIGNAPPROVAL, data, 'POST').then((response) => {
                setOpen(false)
                setSubmitting(false)
                setAlert(response.message)
                if (response.status) {
                    getPayments()
                    getApprovals()
                    setState({ ...state, isOpen: true, severity: 'success' });
                } else {
                    setState({ ...state, isOpen: true, severity: 'error' });
                }
            })
        }, 1500)
    }

    const onDecline = async () => {
        const data = {
            requestId: approval.id,
            comment,
            choice: 0
        }
        setAlert("Processing please wait...")
        setState({ ...state, isOpen: true, severity: 'warning' });

        setSubmitting(true)
        setTimeout(() => {
            postData(SIGNAPPROVAL, data, 'POST').then((response) => {
                setOpen(false)
                setSubmitting(false)
                setAlert(response.message)
                if (response.status) {
                    getPayments()
                    getApprovals()
                    setValue(3)
                    setState({ ...state, isOpen: true, severity: 'success' });
                } else {
                    setState({ ...state, isOpen: true, severity: 'error' });
                }
            })
        }, 1500)
    }

    const onDismiss = () => {
        setOpen(false)
    }

    const handleWriteClose = () => {
        setWriteOpen(false)
    }
    const handleWriteFinish = () => {
        if (comment.trim() !== '') {
            setOpen(true)
            setWriteOpen(false)
            const { amount, to_receive_on, member_name, processor, account_number, membership_account, method } = approval
            const message = `Confirm to approve withdrawal of ${fCurrency(amount)} to be processed at ${to_receive_on} on behalf on ${member_name} to ${processor} A/C ${account_number} - ${membership_account} via ${method}\n\nYour comment: ${comment} `
            setMessage(message)
        } else {
            setWriteOpen(true)
        }
    }
    const handleSnackClose = () => {
        setState({ ...state, isOpen: false, severity: 'error' });
    };
    return (
        <>


            <Dialog open={writeOpen} onClose={handleWriteClose}>
                <DialogTitle>Provide your comment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To process this request, please enter your comments here. We
                        will send notify all admins your decision.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        multiline
                        margin="dense"
                        id="name"
                        label="Remarks"
                        type="text"
                        fullWidth
                        value={comment}
                        onChange={(e) => setRemarks(e.target.value)}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWriteClose}>Cancel</Button>
                    <Button onClick={handleWriteFinish}>Save</Button>
                </DialogActions>
            </Dialog>

            <ConfirmDialog isSubmitting={isSubmitting} onDismiss={onDismiss} open={open} acceptText="Approve" cancelText="Reject" message={message} title='Process Withdrawal Request?' onConfirm={onApprove} onDecline={onDecline} />
            <TableListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
            />

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table size='small'>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={approvals.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                            showSelectAll={false}
                        />
                        <TableBody>
                            {filteredApprovals && filteredApprovals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const { id, processor, account_number, membership_account, member_name, method, amount, complete, reference, total_approvers, total_disapprovers, mobile, registered_mobile, to_receive_on, createdAt, updatedAt } = row;
                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                        >
                                            {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    onChange={(event) => handleClick(event, id)}
                                                />
                                            </TableCell> */}
                                            <TableCell align="right">
                                                {!complete ? <Stack spacing={1} direction='row'>
                                                    <Button onClick={() => {
                                                        setApproval(row);
                                                        setWriteOpen(true)
                                                    }} variant='text' size="small">Process</Button>
                                                </Stack> : <Button color='secondary' variant='text' size="small">No Action</Button>}
                                            </TableCell>
                                            <TableCell align="left">{id}</TableCell>
                                            <TableCell align="left">{processor}</TableCell>
                                            <TableCell align="left">{account_number}</TableCell>
                                            <TableCell align="left">{membership_account}</TableCell>
                                            <TableCell align="left">{member_name}</TableCell>
                                            <TableCell align="left">{method}</TableCell>
                                            <TableCell align="left">{fNumber(amount)}</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={complete}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{reference}</TableCell>
                                            <TableCell align="left">{total_approvers}</TableCell>
                                            <TableCell align="left">{total_disapprovers}</TableCell>
                                            <TableCell align="left">{mobile}</TableCell>
                                            <TableCell align="left">{registered_mobile}</TableCell>
                                            <TableCell align="left">{to_receive_on}</TableCell>
                                            <TableCell align="left">{fDateTime(createdAt)}</TableCell>
                                            <TableCell align="left">{fDateTime(updatedAt)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isGroupNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={approvals.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={20000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {alert}
                </Alert>
            </Snackbar>
        </>
    )
}