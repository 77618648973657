/* eslint-disable-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Stack,
    Button,
    Typography,
    Card,
    Tabs,
    Tab,
    Tooltip
} from '@mui/material';
import useIsMountedRef from 'use-is-mounted-ref';
import Iconify from '../../../../components/Iconify';
import SchedulePayment from './SchedulePayment';
import { APPROVALS } from '../../../../utils/urls';
import { postData, a11yProps } from '../../../../utils/generalFunctions';
import SchedulePayments from '../@tables/ScheduledPayments';
import ApprovalePage from '../@tables/Approvals';
import TabPanel from '../TabPanel';

Payments.propTypes = {
    payments: PropTypes.array,
    getPayments: PropTypes.func,
    group: PropTypes.object
}
Payments.defaultProps = {
    payments: []
}

export default function Payments({ payments, getPayments, group }) {
    const isMountedRef = useIsMountedRef()
    const [approvals, setApprovals] = useState([])
    const [openCreate, setOpenCreate] = useState(false)
    const [value, setValue] = React.useState(0);

    const getApprovals = React.useCallback(async () => {
        if (isMountedRef.current) {
            await postData(`${APPROVALS}${group.id}`, {}, 'GET').then((response) => {
                try {
                    if (response.status) {
                        setApprovals(response.approvals)
                    }
                } catch (error) {
                    console.log(error);
                }
            })
        }
    }, [isMountedRef])

    React.useEffect(() => {
        getApprovals()
    }, [getApprovals])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const openCreateDialog = () => {
        if (openCreate) {
            getApprovals()
        }
        setOpenCreate(!openCreate)
    }
    const renderInProcessing = (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
                <Typography variant="body2" gutterBottom>
                    Tuliza Payments Scheduler
                </Typography>
                <Stack spacing={1} direction="row">
                    <Button
                        variant="text"
                        onClick={getPayments}
                        startIcon={<Iconify icon="eva:refresh-fill" />}
                    >
                        refresh
                    </Button>
                    <Tooltip arrow title='Schedule payments on time. Note all payments require approval from group signatories.'>
                        <Button
                            variant="contained"
                            onClick={openCreateDialog}
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            Schedule Payment
                        </Button>
                    </Tooltip>

                </Stack>
            </Stack>

            <SchedulePayments payments={payments} getPayments={getPayments} />
        </>
    )

    const renderInApproval = (title = 'Tuliza Payments Approval Center') => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mr={3} ml={3} mt={3} mb={3}>
            <Typography variant="body2" gutterBottom>
                {title}
            </Typography>
            <Button
                variant="text"
                onClick={getApprovals}
                startIcon={<Iconify icon="eva:refresh-fill" />}
            >
                refresh
            </Button>
        </Stack>
    )

    return (
        <Card>
            <SchedulePayment setValueSelect={setValue} group={group} open={openCreate} setOpen={openCreateDialog} />

            <Tabs selectionFollowsFocus
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Payments Management"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab sx={{ alignSelf: 'start' }} label="Outgoing Payments" {...a11yProps(0)} />
                <Tab sx={{ alignSelf: 'start' }} label="Pending Approvals" {...a11yProps(1)} />
                <Tab sx={{ alignSelf: 'start' }} label="Approved Schedules" {...a11yProps(2)} />
                <Tab sx={{ alignSelf: 'start' }} label="Declined Schedules" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                {renderInProcessing}
            </TabPanel>

            <TabPanel value={value} index={1}>
                {renderInApproval()}
                <ApprovalePage approvals={approvals.filter(p => !p.complete)} getPayments={getPayments} setValue={setValue} getApprovals={getApprovals} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                {renderInApproval('Approved Schedules')}
                <ApprovalePage approvals={approvals.filter(p => p.total_approvers === `${p.required_approvers}/${p.required_approvers}`)} getPayments={getPayments} setValue={setValue} getApprovals={getApprovals} />

            </TabPanel>

            <TabPanel value={value} index={3}>
                {renderInApproval('Declined Schedules')}
                <ApprovalePage approvals={approvals.filter(p => p.total_disapprovers !== `0/${p.required_approvers}`)} getPayments={getPayments} setValue={setValue} getApprovals={getApprovals} />
            </TabPanel>



            {/* required_approvers */}
        </Card>
    );
}



