/* eslint-disable-line no-unused-vars */

import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import PledgesPaid from './PledgesPaid';
// components
import CurrentBalance from './CurrentBalance';
import TotalContributions from './TotalContributions';
import GroupTransfersGraph from './GroupTransfersGraph';
import CashInHand from './CashInHand';
import Expenses from './Expenses';
import TotalPledged from './TotalPledged';
import useResponsive from '../../../../hooks/useResponsive';
import Budget from './Budget';
// ----------------------------------------------------------------------
DashboardInfoCards.propTypes = {
    fundraiser: PropTypes.object.isRequired
}

export default function DashboardInfoCards({ fundraiser }) {
    const { current_offline_contributions, budget, total_contributions, total_pledges, pledges_paid, expenses, account_balance, total_withdrawals_derived } = fundraiser
    const isDesktop = useResponsive('up', 'md');
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <Budget value={budget} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TotalContributions value={total_contributions} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <CashInHand value={current_offline_contributions} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Expenses value={expenses} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Stack spacing={2}>
                    <CurrentBalance value={account_balance} />
                    <TotalPledged value={total_pledges} />
                    <PledgesPaid value={pledges_paid} />
                </Stack>
            </Grid>
            <Grid hidden={!isDesktop} item xs={12} md={9} lg={9}>
                <GroupTransfersGraph />
            </Grid>
        </Grid>
    );
}
