import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import GroupManage from '../app/GroupManage';

// ----------------------------------------------------------------------
GroupRowOptions.propTypes = {
  setEditOpen: PropTypes.func,
  group: PropTypes.object,
  setSelectedGroup: PropTypes.func
}

export default function GroupRowOptions({ setSelectedGroup, group, setEditOpen }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open)
  }
  return (
    <>
      {open ? <GroupManage open={open} group={group} setOpen={handleClose} /> : ''}

      <Button ref={ref} onClick={() => setIsOpen(true)}> <Iconify icon="eva:eye-fill" />view</Button>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >

        <MenuItem onClick={() => { setOpen(true); setIsOpen(false) }} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-manage-accounts" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Manage Group" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={() => {
          setEditOpen(true);
          setIsOpen(false);
          setSelectedGroup(group)
        }} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit Group" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

      </Menu>

    </>
  );
}
