import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { postData } from '../../../../utils/generalFunctions';
import { CANCEL_SCHEDULE } from '../../../../utils/urls';

CancelSchedule.propTypes = {
    setOpen: PropTypes.func,
    schedule: PropTypes.object,
    open: PropTypes.bool,
}
CancelSchedule.defaultProps = {
    schedule: {
        amount: 0,
        clientName: '',
        to_receive_on: ''
    },
    open: false,
}
export default function CancelSchedule(props) {
    const { setOpen, open, schedule } = props
    const [messageShow, setMessageShow] = React.useState(false)
    const [status, setStatus] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const handleClose = () => {
        setStatus(false)
        setMessage('') 
        setMessageShow(false)
        setOpen(false);
    };


    const handleSubmit = async () => {
        await postData(`${CANCEL_SCHEDULE}/${schedule.id}`, null, 'DELETE')
            .then(response => {
                setMessage(response.message)
                setMessageShow(true)
                setStatus(response.status)
            });
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {messageShow ? (status && 'Success' || 'Ooops!') : 'Cancel schedule?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message.length > 0 ? message : `You are about to unschedule KES${schedule && schedule.amount} by clicking proceed ${schedule && schedule.clientName} will not receive the money at ${schedule && schedule.to_receive_on}.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ display: messageShow ? 'none' : 'block' }} color='info' onClick={handleClose}>Leave it!</Button>
                <Button sx={{ display: messageShow ? 'none' : 'block' }} onClick={handleSubmit} autoFocus>
                    Proceed
                </Button>
                <Button sx={{ display: messageShow ? 'block' : 'none' }} variant='contained' sm onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}