import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import FundraisingManage from '../app/FundraisingManage';

// ----------------------------------------------------------------------
FundraisingOptions.propTypes = {
  item: PropTypes.object
}

export default function FundraisingOptions({ item }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open)
  }
  return (
    <>
      {open ? <FundraisingManage open={open} item={item} setOpen={toggleOpen} /> : ''}
      <Button ref={ref} onClick={toggleOpen}> <Iconify icon="eva:eye-fill" />view</Button>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >

        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-manage-accounts" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Contributions" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

    </>
  );
}
