/* eslint-disable array-callback-return */
import * as React from 'react';
import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useIsMountedRef from 'use-is-mounted-ref';
import Backdrop from '@mui/material/Backdrop';
// import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    Box,
    TextField,
    FormControlLabel,
    Alert,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Snackbar,
    Grid,
    Card,
    Container,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import { postData } from '../utils/generalFunctions';
import { DEPOSIT, DEPOSIT_OPTIONS } from '../utils/urls';
import ConfirmDialog from '../components/ConfirmDialog';
import { fCurrency } from '../utils/formatNumber';

const schema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    mobile: Yup.string()
        .min(10, 'Mobile number is invalid')
        .required('Mobile number is a mandatory field'),
    amount: Yup.number()
        .min(1, 'Minimum amount is KES1')
        .max(999999, 'Maximum amount is KES999999')
        .required('Deposit amount is a mandatory field'),
    reference: Yup.string()
        .min(2, 'Too short!')
        .max(20, 'Too long!')
        .required('Payment reference is a mandatory field'),

    channel: Yup.number('Deposit channel is required'),
});

Recharge.defaultProps = {
    account_no: '',
    amount: 0,
    callbackFunc: null,
    typeValue: 'contribution',
};
export default function Recharge(props) {
    const [message, setMessage] = React.useState('')
    const [option, setOption] = React.useState('')
    const [confirmMessage, setConfirmMessage] = React.useState('')
    const [options, setOptions] = useState([])

    const [state, setState] = React.useState({
        isOpen: false,
        vertical: 'center',
        horizontal: 'center',
        severity: 'error'
    });


    const formik = useFormik({
        initialValues: {
            mobile: '',
            amount: '',
            reference: '',
            channel: '2'
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: (values) => {
            try {
                postData(DEPOSIT, values)
                    .then(data => {
                        if (data.status) {
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'success' });
                            formik.resetForm()
                            formik.setSubmitting(false)
                            toggleOpen()
                        } else {
                            formik.setSubmitting(false)
                            formik.isValidating = false
                            setMessage(data.message)
                            setState({ ...state, isOpen: true, severity: 'error' });
                        }
                    });
            } catch (error) {
                setMessage(error.message)
                setState({ ...state, open: true, severity: 'error' });
                formik.setSubmitting(false)
            }
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { severity, horizontal, vertical, isOpen } = state;


    const isMountedRef = useIsMountedRef();

    const getPaymentOptions = useCallback(async () => {
        try {
            await postData(DEPOSIT_OPTIONS, null, 'GET')
                .then(data => {
                    if (data.status) {
                        if (isMountedRef.current) {
                            setOptions(data.options);
                        }
                    }
                });


        } catch (err) {
            console.log(err.message)
        }
    }, [isMountedRef]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getPaymentOptions();
    }, [getPaymentOptions]);


    const [open, setOpen] = React.useState(false)
    const onConfirm = () => {
        formik.submitForm()
    }
    const handleSnackClose = () => {
        setState({ ...state, isOpen: false });
    };


    const toggleOpen = () => {
        if (open) {
            setOpen(!open)
        } else {
            const option = filter(options, p => `${p.id}` === values.channel)[0].name
            setOption(option)
            const confirmMessage = `Confirm to deposit KES${fCurrency(values.amount)} to via ${option} mobile number ${values.mobile}`

            setConfirmMessage(confirmMessage)
            const errorValues = Object.values(formik.errors)
            if (errorValues.length !== 0) {
                return errorValues.map((error) => {
                    setMessage(error)
                    setState({ ...state, isOpen: true, severity: 'error' });
                })

            }

            if (!formik.dirty) {
                setMessage("All fields are required")
                setState({ ...state, isOpen: true, severity: 'error' });
            } else {
                setOpen(!open)
            }
        }
    }
    return (
        <Page title='Wallet Top-up'>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <ConfirmDialog title={`Deposit Money via ${option}?`} open={open} acceptText='Proceed' showDismiss={false} cancelText='Cancel!' isSubmitting={isSubmitting}
                    message={confirmMessage}
                    onConfirm={onConfirm} onDismiss={toggleOpen} onDecline={toggleOpen} />
            </Backdrop>

            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={20000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Container maxWidth="lg">

                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <Stack direction="column" mr={5} ml={5} mt={5} mb={5}>
                            <FormikProvider value={formik}>
                                <Stack direction='row'>
                                    <Box sx={{ pb: 2 }}>
                                        <Typography variant='body1'>Deposit Money into wallet</Typography>
                                    </Box>
                                </Stack>
                                <Form disabled={isSubmitting} autoComplete="on" noValidate onSubmit={handleSubmit}>
                                    <Stack direction="row" alignItems="center" justifyContent="stretch" sx={{ my: 2 }}>
                                        <FormControl>
                                            <FormLabel id="payment_channel">Deposit Method</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="payment_channel"
                                                name="row-radio-buttons-channel"
                                                {...getFieldProps('channel')}
                                            >
                                                {
                                                    options && options.map(({ id, name }) => <FormControlLabel key={id} value={id} control={<Radio />} label={name} />)
                                                }

                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 3 }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Deposit amount"
                                            {...getFieldProps('amount')}
                                            error={Boolean(touched.amount && errors.amount)}
                                            helperText={touched.amount && errors.amount}
                                        />
                                        <TextField
                                            multiline
                                            fullWidth
                                            type='tel'
                                            label="Mobile number"
                                            {...getFieldProps('mobile')}
                                            error={Boolean(touched.mobile && errors.mobile)}
                                            helperText={touched.mobile && errors.mobile}
                                        />

                                    </Stack>

                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Payment reference (a note e.g. Contribution Deposit)"
                                        {...getFieldProps('reference')}
                                        error={Boolean(touched.reference && errors.reference)}
                                        helperText={touched.reference && errors.reference}
                                    />

                                    <Stack sx={{ mt: 3 }} alignItems="end">
                                        <LoadingButton
                                            autoFocus
                                            size="small"
                                            color='info'
                                            onClick={toggleOpen}
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Initiate Payment
                                        </LoadingButton>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Stack>
                    </Card>
                </Grid>
            </Container>
        </Page>
    );
}
