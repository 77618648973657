import {
    Typography,
    Box,
    Card,
} from '@mui/material';
import TulizaPaymentsTable from '../sections/@dashboard/management/@tables/TulizaPaymentsTable';
import Page from '../components/Page';

export default function TulizaPayments() {
    return (
        <Page title='Transactions'>
            <Card>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ p: 2 }} variant="body1">Tuliza Scheduled Payments</Typography>
                    <TulizaPaymentsTable />
                </Box>

            </Card>
        </Page>
    );
}






